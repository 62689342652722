import React, { useState, useEffect } from 'react';
import './Noticias.css';
import noticia1 from '../../assets/fondonoticias1.jpg';
import noticia2 from '../../assets/fondoplantilla.jpg'; 
import noticia3 from '../../assets/fondonoticias3.jpg';
import noticia4 from '../../assets/fondonoticias4.jpg';

import noticia17feb from '../../assets/noticias/noticia17feb.png';
import noticia11mar from '../../assets/noticias/NOTICIA1103.jpg';


// Componente Modal para mostrar la noticia completa
const Modal = ({ show, onClose, title, date, content }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-noticias">
        <h2>{title}</h2>
        <p className="modal-date">{date}</p> {/* Fecha en el modal */}
        <div dangerouslySetInnerHTML={{ __html: content }} /> {/* Renderizar contenido HTML */}
        <button className="close-button" onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

const Card = ({ id, name = '', date = '', imageUrl, isOpen, handleClick, isMobile, onOpenModal }) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setIsHovered(false);
    }
  }, [isMobile]);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  return (
    <div
      className={`card ${isHovered || isOpen ? 'open' : ''}`}
      onClick={() => isMobile && handleClick(id)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ 
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        flex: isHovered || isOpen ? 2 : 1,
        position: 'relative'
      }}
    >
      <div className="card-overlay"></div>
      <div className="card-content">
        <h2 className="card-title">{name}</h2>
        <p className="card-date">{date}</p> {/* Fecha en la carta */}
        <button className="card-button" onClick={() => onOpenModal(id)}>Ver noticia completa</button>
      </div>
    </div>
  );
};

const Gallery = () => {
  const [openCardId, setOpenCardId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: '', date: '', content: '' });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (id) => {
    setOpenCardId(openCardId === id ? null : id);
  };

  // Contenido de las noticias
  const noticias = {
    
    '1': {
      title: 'Crónica 31 de marzo',
      date: '31 de marzo de 2025',
      content: `
        <p>EQUIPO SENIOR</p>
        <p>Segunda victoria de la temporada para los manzanareños que además abandonan la
última plaza en esta fecha y aseguran su duelo por la salvación ante el Baloncesto
Yuncos en el Play Out a partir del 12 de abril. Victoria balsámica para los de Pablo
Serrano que fueron mejores que su rival venciendo con mucha claridad al del partido.</p>
        <p>EQUIPO JUVENIL</p>
        <p>La derrota de los manzanareños viendo el resto de resultados de la jornada al menos
no les fastidió la fiesta de acceder a los play Off como uno de los dos mejores cuartos.
Cerraba con su décima victoria el equipo daimieleño que le permite acabar un año
complicado en el que han ido de menos a más.</p>
      `
    },
    '2': {
      title: 'Crónica 24 marzo',
      date: '24 de marzo de 2025',
      content:`
        <p>EQUIPO SENIOR</p>
        <p>Su afán por terminar en la primera plaza los de Santiago Ruiz llegarán a la última
jornada dependiendo de sí mismos para acabar siéndolo. Triunfo muy cómodo ante el
colista Manzanares al que arrollaron desde el primer cuarto para sumar la
decimosexta de la temporada.</p>
        <p>EQUIPO JUNIOR</p>
        <p>El derbi se lo llevaron los valdepeñeros que dependerán de si mismos en la última
fecha para su clasificación directa. Se quedan con 13 victorias y con 12 Manzanares
pero podría darse la circunstancia que estos dos más Puertollano acabaran pasando
de ronda todos por su número de victorias. En este partido Valdepeñas mandó en el
marcador de principio a fin y no dio chances a los manzanareños</p>  `},

    '3': {
      title: 'Crónica 17 marzo',
      date: '17 de marzo de 2025',
      content: `
        <p>EQUIPO SENIOR</p>
        <p>Octava victoria para los hellineros en su visita al colista que les mantiene todavía vivos
en la pelea por la última plaza que da acceso al Play Off, aunque eso si, con las
opciones remotas al estar a dos victorias de Cervantes a dos fechas para el final.
Dominio prácticamente en todos los parciales ante los manzanareños para ganar en el
Antonio Caba una vez más</p>
        <p>EQUIPO JUNIOR</p>
        <p>Los de Ricky no podían desaprovechar la oportunidad de sumar, más si cabe tras
conocer el triunfo de Valdepeñas y la derrota de Puertollano para seguir segundos en la
tabla peleando por acceder a los play off. Poco que contar del partido que con el 20-4
quedó más que encarrilado.</p>
        `
    },
    '4': {
      title: 'Crónica 10 marzo',
      date: '10 de marzo de 2025',
      content: `
         <p>EQUIPO SENIOR</p>
        <p>Llegó la primera y ansiada victoria para los manzanareños dirigidos por Pablo Serrano.
El derbi comarcal ante Almagro se lo llevaron los locales en el Antonio Caba para
acabar con una mala racha que duraba desde el inicio de temporada. El 25-9 del
segundo cuarto rompía el partido a su favor para sacar este primer triunfo.
Manzanares ya es equipo de Play Out de forma matemática y está claro que esta
victoria les sirve para preparar lo que serán en unas fechas dos partidos
fundamentales para la salvación. Por su parte Almagro tiene complicado salir de esta
misma situación pero todavía podría hacerlo en una gran carambola.</p>
        <p>EQUIPO JUNIOR</p>
        <p>Duelo de equipos punteros que pone en un hilo la pelea por las dos plazas en juego
para el acceso directo sin repesca a los Play Off. Con todo igualado los industriales
rompieron la contienda en los últimos diez con un parcial de 13-22 que sentenció a su
favor y que deja con 11 victorias, a ellos, a Manzanares y a Valdepeñas.</p>
<img src="${noticia11mar}" alt="Noticia" style="width:80%; margin-top: 20px;" /> 
      `  
      
    }
    
        
  };

  const handleOpenModal = (id) => {
    const { title, date, content } = noticias[id];
    setModalData({ title, date, content });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <section className="gallery-container" id="galeria">
        <Card 
        id="1"
        name="Crónica 31 marzo"
        date="31 de marzo de 2025"
        imageUrl={noticia2}
        isOpen={openCardId === '1'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
        <Card 
        id="2"
        name="Crónica 24 marzo"
        date="24 de marzo de 2025"
        imageUrl={noticia3}
        isOpen={openCardId === '2'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
     <Card 
        id="3"
        name="Crónica 17 marzo"
        date="17 de marzo de 2025"
        imageUrl={noticia4}
        isOpen={openCardId === '3'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      /> 
        <Card 
        id="4"
        name="Crónica 10 marzo"
        date=" "
        imageUrl={noticia1}
        isOpen={openCardId === '4'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />    
        
      {/* Modal para mostrar la noticia completa */}
      <Modal 
        show={showModal} 
        onClose={handleCloseModal} 
        title={modalData.title} 
        date={modalData.date} 
        content={modalData.content} 
      />
    </section>
  );
};

export default Gallery;
